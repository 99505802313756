import React from 'react';
import { processLanguage, isValidNumber, APICall } from '../API';
import {copy} from "../Language"
import {Navigate} from "react-router-dom"
import { getCountries } from '../FormOptions';

class VerifyMobile extends React.Component{

    constructor(props){
        super(props);
        this.language = processLanguage()
        this.otp = null
        this.OTPSubmited = false
        this.errorMessage = null
        this.success = false
        this.loading = false
        this.resendingOTP = false
    
        if(null===window.sessionStorage.getItem("mobile")){
            window.top.location = "/"
            return
        }
    }

    submit = async (e) =>{
        e.preventDefault()
        this.errorMessage = null

        if(this.loading || this.resendingOTP){
            return
        }

        if( this.otp===null ){
            this.errorMessage = copy.otp_must_be_filled[this.language]
            this.success = false
            this.forceUpdate()
            return
        }

        this.loading = true
        this.forceUpdate()

        const payload = {
            mobile:window.sessionStorage.getItem("mobile").replace("+",""),
            activation_code:parseInt(this.otp)
        }

        const res = await APICall("verify-mobile-number",payload,"POST")

        if(res.code===200){

            window.sessionStorage.removeItem("mobile")
            window.sessionStorage.removeItem("country")
    
            this.OTPSubmited = true
            this.success = true    
        }else{
            this.success = false
            this.errorMessage = copy.invalid_otp[this.language]
        }

        this.loading = false
        this.forceUpdate()
    }

    resendOTP = async () =>{
        if(this.loading || this.resendingOTP){
            return
        }


        this.resendingOTP = true
        this.forceUpdate()
        const payload = {
            mobile:window.sessionStorage.getItem("mobile").replace("+","")
        }

        const res = await APICall("resend-activation-code",payload,"POST")

        this.resendingOTP = false
        this.forceUpdate()

    }


    render(){

        if(this.OTPSubmited){
            return <Navigate to={"/registration-completed/"+this.language} />
        }

        return(
        <div className="row noMargin" id="numberForm">
            <div className="col-12 h-100 d-flex">
                <div className="row fillSpace">

                    <div className="col-12 formHolderTopLogoHolder">
                        <div className="row">
                            <div className="container">
                                <div className="row">

                                    <div className="col-12">
                                        
                                        <div className="text-center">
                                                <img src="/assets/img/swali_green.png" className="img-fluid" id="swaliLandingLogo" alt="Swali Logo"/>				
                                                <p>&nbsp;&nbsp;</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 yellowBG formHolder">
                        <div className="row">

                            <div className="container">
                                <div className="row">

                                    <div className="col-12" >
                                            
                                        <div className="text-center">
                                            <p>
                                                <strong dangerouslySetInnerHTML={{__html:copy.enter_otp[this.language]}}>
                                                </strong>
                                            </p>
                                            <form onSubmit={this.submit}>
                                                {
                                                    this.errorMessage !== null ? <p className={this.success ? "text-success" : "text-danger"}>{this.errorMessage}</p> : null
                                                }
                                                <input id="mobileNumberField" onChange={(e)=>this.otp = e.target.value} type="tel" className="form-control text-center noArrow m-auto d-inline"  placeholder={copy.otp_verification_code[this.language]} />
                                                <p><br/></p>
                                                {
                                                    this.resendingOTP ? 
                                                    <p className='text-center'><span className='fa fa-spin fa-spinner'></span></p> :
                                                    <p 
                                                        onClick={this.resendOTP}
                                                        dangerouslySetInnerHTML={{__html:copy.did_not_receive_otp[this.language]}}>
                                                    </p>
                                                }
                                                <p><br/></p>
                                                {
                                                    this.loading ? 
                                                    <p className='text-center'><span className='fa fa-spin fa-spinner'></span></p> :
                                                    <button href="" className="btn btn-green">{copy.next[this.language]}</button>
                                                }
                                            </form>
                                        </div>
                                        
                                    
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                </div>

            </div>
        </div>
        )
    }
}
//
export default VerifyMobile