export const contactMobile = "+255 762 016 002"

export const copy = {
  select_language:{
    en:"Select Language",
    sw:"Chagua Lugha"
  },
  answer_question:{
    en:"Answer Questions. Earn Airtime",
    sw:"Jibu maswali. Pata Muda wa Maongezi"
  },
  can_not_use_respondent_number:{
    en:"You can not use our respondent number as your mobile number",
    sw:"Hauwezi kutumia namba yetu kama namba yako"
  },
  get_started:{
    en:"Get Started",
    sw:"Anza"
  },
  next:{
    en:"NEXT",
    sw:"ENDELEA"
  },
  fill_mobile:{
    en:"Fill mobile number",
    sw:"Jaza namba ya simu"
  },
  fill_phone_number:{
    en:"Fill phone number starting with country code e.g +255 XXX XXX XXX",
    sw:"Jaza namba ya simu ukianza na kodi ya nchi m.f +255 XXX XXX XXX"
  },
  phone_number:{
    en:"Phone number",
    sw:"Namba ya simu"
  },
  country_must_be_selected:{
    en:"Country must be selected",
    sw:"Nchi lazima ichaguliwe"
  },
  mobile_must_be_filled:{
    en:"Mobile number must be filled",
    sw:"Namba ya simu lazima ijazwe"
  },
  invalid_mobile_no:{
    en:"Mobile number is not valid",
    sw:"Namba ya simu sio sahihi"
  },
  how_it_works_title:{
    en:"Swali is a survey tool that rewards users for participating in surveys.  All surveys are strictly "+
    "for market research purposes only and all answers are strictly confidential.",
    sw:"Swali ni chombo cha utafiti kinacho wazawadia watuamiaji kwa ushiriki wao. Tafiti zote ni kwa ajili ya "+
    "soko tu na majibu yote ni ya siri kabisa.",
  },
  how_it_works_body:{
    en:"<p><br/><strong>How does it work:</strong></p> <br/><ol>"+

    "<li>Register on swalitime.com</li>"+
    "<li>Fill in information.  This information helps us assign surveys to you.</li>"+  
    "<li>You will receive a code to verify your phone number.</li>"+ 
    "<li>When there is a survey for you, you will receive an link to the survey via sms.</li>"+
    "<li>Click on the link and complete the survey.</li>"+
    "<li>After you complete the survey, you will receive a reward.</li></ol>",
    sw:"<p><br/><strong>Jinsi inavyo fanya kazi::</strong></p> <br/><ol>"+
    "<li>Jisajili kwenye swalitime.com</li>"+
    "<li>Jaza taarifa zako. Maelezo haya hutusaidia kukupa tafiti zitakazo endana na wewe.</li>"+  
    "<li>Utapokea msimbo wa kuthibitisha nambari yako ya simu.</li>"+ 
    "<li>Kama kutakua na utafiti kwa ajili yako, utapokea linki ya utafiti kupitia sms.</li>"+
    "<li>Bonyeza Linki na ukamilishe utafiti</li>"+
    "<li>Baada ya kukamilisha utafiti, utapokea zawadi.</li></ol>",
  },
  important_stuff_to_know_title:{
    en:"IMPORTANT STUFF TO KNOW",
    sw:"BAADHI YA VITU MUHIMU KUJUA:"
  },
  important_stuff_to_know_body:{
    en:"<ol><li>You can only complete a survey once.</li>"+  
    "<li>You can only complete surveys that you received via SMS from Swali.</li>"+ 
    "<li>Each survey will tell you the reward amount before you begin. </li>"+
    "<li>Some surveys do not offer a reward, this is told to you before you begin. </li>"+
    "<li>Surveys are random and do not follow a release schedule. </li>"+
    "<li>Surveys will expire after a certain number of people have completed them. <br/>" +
    "So you may click on the link and the survey has already closed. <br/>"+
    "For best results, participate as soon as you receive the link. </li>"+ 
    "</ol>",
    sw:"<ol><li>Unaweza kukamilisha tafiti mara moja tu.</li>"+  
    "<li>Unaweza kukamilisha tafiti ulizopokea kupitia SMS kutoka Swali.</li>"+ 
    "<li>Kila utafiti utakuambia kiasi cha zawadi kabla ya kuanza.</li>"+
    "<li>Tafiti zingine hazitakuwa na zawadi, hii utajulishwa kabla ya kuanza.</li>"+
    "<li>Tafiti hazijapangiliwa na huwa hazitoki kwa ratiba.</li>"+
    "<li>Tafiti zitaisha baada ya idadi fulani ya watu kukamilisha majibu. <br/>"+
    "Kwa hiyo unaweza bonyeza linki ya tafiti ukakuta imefungwa. <br/>"+
    "Kwa matokeo bora zaidi, shiriki mara tu upokeapo Linki. </li>"+ 
    "</ol>",
  },
  create_account:{
    en:"Create Account",
    sw:"Tengeneza Akaunti"
  },
  registration:{
    en:"REGISTRATION",
    sw:"KUJIANDIKISHA"
  },
  registration_message:{
    en:"By pressing <strong>CONFIRM</strong> you agree to be contacted and participate in surveys<br/> and will receive a SMS Verification Code",
    sw:"Kwa kubonyeza <strong>WASILISHA</strong> umekubali kupigiwa simu yetu na kushiriki kwenye utafiti tunaofanya<br/> na utapokea namba ya utambulisho utayo hakiki kwa njia ya SMS"
  },
  continue:{
    en:"Continue",
    sw:"Endelea"
  },
  confirm:{
    en:"CONFIRM",
    sw:"WASILISHA"
  },
  country:{
    en:"Country",
    sw:"Nchi"
  },
  select_country:{
    en:"Select Country",
    sw:"Chagua Nchi"
  },
  region:{
    en:"Region",
    sw:"Mkoa"
  },
  select_region:{
    en:"Select Region",
    sw:"Chagua Mkoa"
  },
  area_code:{
    en:"Area Code",
    sw:"Area Code"
  },
  gender:{
    en:"Gender",
    sw:"Jinsia"
  },
  select_gender:{
    en:"Select Gender",
    sw:"Chagua Jinsia"
  },
  birth_year:{
    en:"Birth Year",
    sw:"Mwaka Uliozaliwa"
  },
  select_birth_year:{
    en:"Select birth year",
    sw:"Chagua mwaka uliozaliwa"
  },
  relationship_status:{
    en:"What best describes your relationship status?",
    sw:"Ni nini kinachoelezea vizuri hali yako ya mahusiano?"
  },
  select_appropriate:{
    en:"Select appropriate",
    sw:"Chagua inayofaa"
  },
  are_you_a_parent:{
    en:"Do you have children?",
    sw:"Je una mtoto?"
  },
  child_birth_years_before_last_year:{
    en:"Before year {{LAST_YEAR}}",
    sw:"Kabla ya mwaka {{LAST_YEAR}}"
  },
  child_n_birth_year:{
    en:"Child {{NN}} birth year",
    sw:"Mwaka aliozaliwa mtoto wa {{NN}}"
  },
  education_level:{
    en:"What is your education level?",
    sw:"Kiwango chako cha elimu yako ni kipi?"
  },
  source_of_income:{
    en:"What is your primary source of income?",
    sw:"Nini chanzo chako cha msingi cha mapato?"
  },
  yes:{
    en:"Yes",
    sw:"Ndio"
  },
  no:{
    en:"No",
    sw:"Hapana"
  },
  country_must_be_filled:{
    en:"Country must be filled",
    sw:"Nchi lazima ichaguliwe"
  },
  region_must_be_filled:{
    en:"Region must be filled",
    sw:"Mkoa lazima uchaguliwe"
  },
  gender_must_be_filled:{
    en:"Gender must be filled",
    sw:"Jinsia lazima ichaguliwe"
  },
  birth_year_must_be_filled:{
    en:"Birth year must be filled",
    sw:"Mwaka wa kuzaliwa lazima ujazwe"
  },
  realtionship_must_be_filled:{
    en:"Relationship status must be filled",
    sw:"Mahusiano lazima yajazwe"
  },
  parenthood_must_be_filled:{
    en:"Parenthood must be specified",
    sw:"Lazima ujaze kama ni mzazi au la"
  },
  education_must_be_filled:{
    en:"Education level must be filled",
    sw:"Kiwango cha elimu yako lazima kijazwe"
  },
  source_of_income_must_be_filled:{
    en:"Your primary source of income must be filled",
    sw:"Chanzo chako cha mapato lazima kijazwe"
  },
  succesfully_registered:{
    en:"You have successfuly <br/>registered to participate in <br/>",
    sw:"Umefanikiwa kujiandikisha na kushiriki katika <br/> "
  },
  you_will_receive_surveys_when_they_are_available:{
    en:"You will receive SMS with links to surveys when they are available for you.",
    sw:"Uchunguzi utapokuwa tayari utatumiwa ujumbe kwa njia ya SMS yenye link."
  },
  successful_registration_more:{
    en:"Please confirm your details by sending <strong>{{CODE}}</strong> to <strong>"+contactMobile+"<strong> <br/> on <span class='greenText'>WhatsApp</span>",
    sw:"Tafadhali hakiki taarifa zako kwa kutuma <strong>{{CODE}}</strong> kwenda <strong>"+contactMobile+"</strong> <br/>kwa kutumia <span class='greentext'>WhatsApp</span>"
  },
  enter_otp:{
    en:"Please enter the 4-digit verification code that was sent to your phone number. <br/>This code will be valid for 30 minutes",
    sw:"Tafadhali weka uthibitishaji wa tarakimu 4 uliotumwa kwenye namba yako ya simu. Kodi hii itatumika kwa dakika 30.",
  },
  otp_must_be_filled:{
    en:"Please fill the otp field",
    sw:"Tafadhali jaza eneo la Msimbo",
  },
  otp_verification_code:{
    en:"OTP verification code",
    sw:"Msimbo"
  },
  did_not_receive_otp:{
    en:"Did not receive OTP?, <span class='greenText cursor'>Click here</span> to resend",
    sw:"Haujapokea msimbo?, <span class='greenText cursor'>Bonyeza hapa</span> kutumiwa upya",
  },
  invalid_otp:{
    en:"The entered OTP was invalid",
    sw:"Msimbo uliojazwa sio sahihi",
  },

  next_question:{
    en:"NEXT",
    sw:"ENDELEA"
  },
  previous_question:{
    en:"PREVIOUS",
    sw:"RUDI"
  },
  question:{
    en:"Question ",
    sw:"Swali la "
  },
  no_questions:{
    en:"This survey has no questions",
    sw:"Hamna maswali kwa sasa"
  },
  could_not_submit:{
    en:"Could not submit, please check your internet connection",
    sw:"Imeshindikana kuwasilisha, tafadhali hakikisha una intaneti"
  },
  congratulations:{
    en:"Congratulations",
    sw:"Hongera"
  },
  you_won:{
    en:"You have won airtime, you will receive a notification soon",
    sw:"Umejishindia muda wa maongezi, utapokea ujumbe hivi punde"
  },
  complete_media:{
    en:"Complete the media content before proceeding",
    sw:"Malizia kusikiliza/kuangalia ili uendelee"
  },
  drinker:{
    en:"Do you drink alcohol?",
    sw:"Je unatumia pombe?"
  },
  where_do_you_keep_your_money:{
    en:"Where do you keep your money?",
    sw:"Je pesa zako unaweka wapi?"
  }

}