import React from 'react';
import { processLanguage } from '../API';
import {copy} from "../Language"

class RegistrationCompleted extends React.Component{

    constructor(props){
        super(props);
        this.language = processLanguage()
    }

    render(){

        return(
            <div className="row noMargin fillScreen">
                
                <div className="container" style={{alignSelf:"center"}}>
                
                    <div className="row">
                        
                        <div className="col-12 text-center h-100" id="">
                            <div>
                                <h1 dangerouslySetInnerHTML={{__html:copy.succesfully_registered[this.language]}}></h1>
                                <img src="/assets/img/swali_green.png" class="img-fluid" alt="Swali" />
                                <br/><br/>
                                <h4 dangerouslySetInnerHTML={{__html:copy.you_will_receive_surveys_when_they_are_available[this.language]}}></h4>				
                            </div>
                        </div>
                    
                    </div>
                
                </div>


            </div>
        )
    }
}
//
export default RegistrationCompleted