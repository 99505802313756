import React from 'react';
import {copy} from "../Language"
import { getRespondentUser } from '../API';
import KRMLogo from "../assets/krm-logo.png"


class SurveyEnded extends React.Component{
    constructor(props){
        super(props)
        this.respondentUser = null
        this.language = null
        this.state = {
            error :null
        }

    }
    componentDidMount(){
        this.respondentUser = getRespondentUser()
        if(this.respondentUser === null){
            this.setState({error:"Could not load page!"})
            return
        }

        this.language = this.respondentUser.language.toLowerCase() === "english" ? "en" : "sw"
        this.forceUpdate()
    }

    render(){

        if(this.state.error!==null){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <h4 className="text-danger">{this.state.error}</h4>
                    </div>
                </div>
            )
        }


        return(
                <div className="row picturedBG noMargin">
                    <div className="container">
                        <div className="row h-100">

                            <div className="col-12">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 text-right">
                                                <img src="/assets/img/swali_white.png" className="img-fluid" alt="Swali Logo" id="topLogo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12" id="landingPageContainer">
                                
                                
                                <div className="text-center">
                                    <h1 className="text-white"><strong>{copy.congratulations[this.language]}</strong></h1>
                                    <span className="btn btn-white">{copy.you_won[this.language]}</span>
                                </div>
                                
                                <div className="logoBottom">
                                        <img src={KRMLogo} className="img-fluid bottomLogo float-right" alt="Khangarue" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
        )
    }
}
//
export default SurveyEnded