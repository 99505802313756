import TanzaniaFlag from "./assets/tanzania-flag.png"

let countries = [{"name":"Tanzania","flag":TanzaniaFlag,"country_area_code":"+255","number_length":13,"code":"TZ"}]
const tanzaniaRegions = ["Arusha", "Dar es Salaam", "Dodoma", "Geita", "Iringa", "Kagera", "Katavi", "Kigoma",
                        "Kilimanjaro", "Lindi", "Manyara", "Mara", "Mbeya", "Morogoro", "Mtwara", "Mwanza",
                        "Njombe", "Pemba Kaskazini", "Pemba Kusini", "Pwani", "Rukwa", "Shinyanga",
                        "Simiyu", "Singida", "Songwe", "Tabora", "Tanga", "Unguja Kaskazini", "Unguja Magharibi",
                        "Unguja Kusini"];

let regions = {
  "Tanzania":tanzaniaRegions
}

let genders = {
  "Male":"Mwanaume",
  "Female":"Mwanamke"
}

let relationshipStatus = {
  "I am single":"Mimi sijaoa",
  "I am married or live with my partner":"Nimeolewa au naishi na mwenzangu",
  "I am in a relationship":"Niko kwenye mahusiano"
}

let educationLevels = {
  "Primary":"Msingi",
  "Secondary":"Sekondari",
  "Bachelor's Degree":"Chuo au Mafunzo ya Ufundi",
  "Masters Degree or above":"Shahada ya Uzamili au zaidi"
}

let primarySourcesOfIncome = {
  "Unemployed":"Huna kazi",
  "Housewife":"Mama wa nyumbani",
  "Day Labor/Casual Labor":"Kibarua",
  "Informal Salary":"Mshahara usio rasmi",
  "Formal Salary Employment":"Ajira rasmi ya Mshahara",
  "Self Employed/ Day Trader":"Kujiajiri / Mfanyabiashara wa Siku",
  "Own a business":"Miliki biashara"
}

let whereDoYouKeepYourMoney = {
  "I have a bank account":"Nina akaunti ya benki",
  "I only use mobile money":"Ninatumia huduma za pesa za mitandao ya simu pekee",
  "A SACCOS or VIKOBA":"SACCOS na VIKOBA",
  "A lending group":"Kikundi cha mikopo",
  "I keep cash at home":"Ninaweka pesa nyumbani",
}

let doYouConsumeAlcohol = {
  "No":"Hapana",
  "Yes, Beer is my favorite":"Ndio, Ninapendelea bia",
  "Yes, Wine is my favorite":"Ndio, ninapendelea mvinyo",
  "Yes, Spirits are my favorite":"Ndio, ninapendelea pombe kali",
}

export function getGenders(){
  let formatedGenders = []
  for(const[key,val] of Object.entries(genders)){
    formatedGenders.push({en:key,sw:val})
  }

  return formatedGenders
}

export function getRelationshipStatus(){
  let relationship = []
  for(const[key,val] of Object.entries(relationshipStatus)){
    relationship.push({en:key,sw:val})
  }
  
return relationship
}

export function getEducationLevels(){
  let education = []
  for(const[key,val] of Object.entries(educationLevels)){
    education.push({en:key,sw:val})
  }

return education
}

export function getPrimarySourcesOfIncome(){
  let sourceOfIncome = []
  for(const[key,val] of Object.entries(primarySourcesOfIncome)){
    sourceOfIncome.push({en:key,sw:val})
  }

  return sourceOfIncome
}

export function getCountries(){
  return countries
}

export function getCountryRegions(country){
  if(country===""){
    return [];
  }
  return regions[country]
}

export function getWhereMoneyIsKept(){
  let whereMoneyIsKept = []
  for(const[key,val] of Object.entries(whereDoYouKeepYourMoney)){
    whereMoneyIsKept.push({en:key,sw:val})
  }

  return whereMoneyIsKept
}

export function getAlcoholConsumption(){
  let alcoholConsumption = []
  for(const[key,val] of Object.entries(doYouConsumeAlcohol)){
    alcoholConsumption.push({en:key,sw:val})
  }

  return alcoholConsumption
}



//determineMediaTypeFromQuestionMediaData accepts media data from a question
//processes it and brings out media type, this gives preference in the following order
//Video>Audio>Photo
export function determineMediaTypeFromQuestionMediaData(mediaData){
   if(mediaData.video.english !== "" || mediaData.video.swahili !== ""){
     return "Video"
   }else if(mediaData.audio.english !== "" || mediaData.audio.swahili !== ""){
     return "Audio"
   }else if(mediaData.photo.english !== "" || mediaData.photo.swahili !== ""){
     return "Photo"
   }

   return "Text"
}

export function languageDecoder(language){
  language = language.toLowerCase()
  if(language==="en"){
    return "english"
  }else{
    return "swahili"
  }
}