import React from 'react';
import {copy} from "../Language"
import {Link, Navigate} from "react-router-dom"
import { getCurrentGroup,getLastQuestionResponse,navigateGroups,processMedia,APICall, getLanguage, setLanguage} from '../API';
import {determineMediaTypeFromQuestionMediaData,languageDecoder} from "../FormOptions"

class QuestionsGroup extends React.Component{

    constructor(props){
        super(props);
        if(getLanguage()===null){
            this.language = window.top.location.href.split("/")
            this.language = this.language[this.language.length-1].split("?")
            this.language = this.language[0]    
            setLanguage(this.language)
        }else{
            this.language = getLanguage()
        }

        this.next = window.top.location.search.split("?next=")
        if(this.next.length>1){
            this.next = true
        }else{
            this.next = false
        }
        
        this.nextNavigationEnabled = true
        this.state = {groupData:null,mediaData:null,error:null,surveyCompleted:false,redirectToQuestions:false}
    }

    handleMediaLastScenario = (currentGroup,forceNext) =>{
        if(currentGroup.media_first===false){
            if(forceNext===false){
                this.setState({redirectToQuestions:currentGroup.ID})
                return false
            }else{
                return true
            }
        }

        return false
    }

    processLastQuestion = async () =>{
        let lastQuestionResponse =  getLastQuestionResponse()
        lastQuestionResponse.last_question = true
        const res = await APICall("add-response",lastQuestionResponse,"POST")
        if(res.code!==200){
            this.setState({error:res.message})
        }
    }

    componentDidMount = async () =>{
        let currentGroup = getCurrentGroup()
        let surveyCompleted = false
        
        if(this.next===false){
            if(currentGroup===undefined){
                window.top.location = "/"
                return 
            }    
            this.handleMediaLastScenario(currentGroup,this.next)

        }else{
            let tmp = JSON.parse(JSON.stringify(currentGroup))

            if( this.handleMediaLastScenario(currentGroup,this.next) === true ){

                //here we show the details first then we navigate to the next one
                tmp = navigateGroups("next");
                if(tmp.ID===currentGroup.ID){
                    surveyCompleted = true
                    this.processLastQuestion()
                }
                currentGroup.ID = tmp.ID


            }else{

                currentGroup = navigateGroups("next");

                if(tmp.ID===currentGroup.ID){
                    surveyCompleted = true
                    this.processLastQuestion()
                }
   

            }

        }

        this.setState({
            surveyCompleted:surveyCompleted,
            groupData:currentGroup,
            mediaData:this.processAssociatedMedia(currentGroup.media)
        })
    }


    togglePlayer = () =>{
        let x = document.getElementById("mediaPlayer")
        if(x.paused){
            x.play()
        }else{
            x.pause()
        }
    }



    mediaCompleted = () =>{
        this.nextNavigationEnabled = true
        this.forceUpdate()
    }

    processAssociatedMedia = (media) =>{
        let mediaZone = null
        switch( determineMediaTypeFromQuestionMediaData(media) ){
            case "Audio":
                this.nextNavigationEnabled = false
                mediaZone = <div className="col text-center surveyLandingElements">
                                <div className="iconHolder" onClick={this.togglePlayer}>
                                        <span className="fa fa-microphone-alt fa-3x greenText"></span>
                                </div>
                                {mediaZone = processMedia(media,languageDecoder(this.language),this.mediaCompleted)}
                            </div>  

                break;

            case "Video":
                this.nextNavigationEnabled = false
                mediaZone = <div className="col text-center surveyLandingElements">
                                {mediaZone = processMedia(media,languageDecoder(this.language),this.mediaCompleted)}
                            </div>  

                break;

            case "Photo":
                this.nextNavigationEnabled = true
                mediaZone = <div className="col text-center surveyLandingElements">
                                {mediaZone = processMedia(media,languageDecoder(this.language))}
                            </div>  
                break;
            
            default:
                break
        }

        return mediaZone

    }    


    render(){
        if(this.state.loading || this.state.groupData===null){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <span className="fa fa-4x fa-spin fa-spinner"></span>
                    </div>
                </div>
            )
        }

        if(this.state.error!==null){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <h4 className="text-danger">{this.state.error}</h4>
                    </div>
                </div>
            )
        }

        if(this.state.redirectToQuestions!==false){
            return <Navigate to={"/answer-questions/"+this.state.redirectToQuestions} />
        }

        if(this.state.surveyCompleted){
            return <Navigate to="/survey-ended" />
        }

        return(
                <div className="row noMargin">
                    
                    <div className="col-12">
                        <div className="row">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <img alt="Swali" src="/assets/img/swali_green.png" className="img-fluid" id="topLogo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 addBackGroundArrows">
                        <div className="row">
                            <div className="container">
                                <div className="row">

                                    {
                                        this.state.mediaData === null ? 
                                        <div className="col-12 surveyLandingElements text-center" >
                                            <h1 className="surveyName" dangerouslySetInnerHTML={{__html:this.state.groupData.title[languageDecoder(this.language)]}} ></h1>
                                            <p className="surveyName" dangerouslySetInnerHTML={{__html:this.state.groupData.intro_text[languageDecoder(this.language)]}} ></p>
                                        </div>                                        
                                        :
                                        <div className="col-sm-12 col-md-6 surveyLandingElements" >
                                            <h1 className="surveyName" dangerouslySetInnerHTML={{__html:this.state.groupData.title[languageDecoder(this.language)]}} ></h1>
                                            <p className="surveyName" dangerouslySetInnerHTML={{__html:this.state.groupData.intro_text[languageDecoder(this.language)]}} ></p>
                                        </div>
                                    }

                                    {
                                        this.state.mediaData
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row noMargin">
                            <div className={"col-12 text-right "+(this.nextNavigationEnabled===false ? "disabled" : "")}>
                                <Link to={"/answer-questions/"+this.state.groupData.ID} className="greenText nextLink" >{copy.next[this.language]} <img src="/assets/img/next.png" className="img-fluid directionImage" alt="next" /></Link>
                                <br/>
                                {
                                    this.nextNavigationEnabled===false ?
                                    <span>{copy.complete_media[this.language]}</span> 
                                    : null
                                }
                            </div>
                        </div>

                    </div>

                </div>
        )
    }
}
//
export default QuestionsGroup