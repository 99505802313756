import React from 'react';
import { processLanguage, APICall } from '../API';
import {copy} from "../Language"
import {Navigate} from "react-router-dom"
import {getAlcoholConsumption, getCountries, getCountryRegions, getEducationLevels, getGenders, getPrimarySourcesOfIncome, getRelationshipStatus, getWhereMoneyIsKept} from "../FormOptions"

let child = {birth_year:""}

class CompleteRegistration extends React.Component{

    constructor(props){
        super(props);
        this.language = processLanguage()
        this.mobile = window.sessionStorage.getItem("mobile")

        this.userDetails = {
                country:window.sessionStorage.getItem("country"),
                region:"",
                area_code:this.mobile.substring(0,4),
                mobile:this.mobile.substring(4),
                gender:"",
                birth_year:"",
                relationship:"",
                parent:"",
                children:[],
                education_level:"",
                source_of_income:"",
                where_money_is_kept:"",
                alcohol_consumption:"",
                language:this.language
            }

        this.birthYears = [];
        this.childBirthYears = []
        let ctr = 18
        this.loading = false
        const currentYear = new Date().getFullYear()
        while(ctr<60){
            this.birthYears.push(currentYear-ctr)
            ctr++
        }

        ctr = 0;
        while(ctr<30){
            this.childBirthYears.push(currentYear-ctr)
            ctr++
        }
        const childBornBeforeLeastYearLabel = copy.child_birth_years_before_last_year[this.language].replace("{{LAST_YEAR}}",this.childBirthYears[this.childBirthYears.length-1]) 
        this.childBirthYears.push(childBornBeforeLeastYearLabel)

        this.errorMessage = null
        this.success = false
        this.redirectToConfirmation = false
    }

    componentDidMount(){
        this.getUser()
    }

    getUser = async () =>{
        const res = await APICall("get-respondent-user?mobile="+this.mobile.substring(1),null,"GET")
        if(res.code===200){
            this.userDetails = res.payload
            this.userDetails.parent = ""+this.userDetails.parent
            this.forceUpdate()
        }
    }

    entryChange = (e) =>{
        this.userDetails[e.target.name] = e.target.value
        if(e.target.name==="country"){
            this.userDetails.region = ""
        }
        if(e.target.name==="drinker"){
            this.userDetails[e.target.name] = e.target.value === "true" ? true : false
        }
        this.forceUpdate()
    }

    childBirthYearChange = (e) =>{
        switch(e.target.name){
            case "child_1":
                if(this.userDetails.children[0]===undefined){
                    let tmp = {...child}
                    tmp.birth_year = e.target.value
                    this.userDetails.children.push(tmp)
                }else{
                    this.userDetails.children[0].birth_year = e.target.value
                }
                break
            
            case "child_2":
                if(this.userDetails.children[1]===undefined){
                    let tmp = {...child}
                    tmp.birth_year = e.target.value
                    this.userDetails.children.push(tmp)
                }else{
                    this.userDetails.children[1].birth_year = e.target.value
                }
                break

            case "child_3":
                if(this.userDetails.children[2]===undefined){
                    let tmp = {...child}
                    tmp.birth_year = e.target.value
                    this.userDetails.children.push(tmp)
                }else{
                    this.userDetails.children[2].birth_year = e.target.value
                }
                break

            default:
                break
        }

        this.forceUpdate()
    }

    submit = async (e) =>{
        e.preventDefault()
        if(this.loading===true){
            return
        }

        this.success = false
        this.errorMessage = ""

        if(this.userDetails.country===""){
            this.errorMessage = copy.country_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.region===""){
            this.errorMessage = copy.region_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.gender===""){
            this.errorMessage = copy.gender_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.birth_year===""){
            this.errorMessage = copy.birth_year_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.relationship_status===""){
            this.errorMessage = copy.realtionship_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.parent===""){
            this.errorMessage = copy.parenthood_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.education_level===""){
            this.errorMessage = copy.education_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        if(this.userDetails.source_of_income===""){
            this.errorMessage = copy.source_of_income_must_be_filled[this.language]
            this.forceUpdate()
            return
        }

        let childFound = false
        for(let x = 0 ; x < this.userDetails.children.length ; x++){
            if(this.userDetails.children[x].birth_year!==""){
                childFound = true
                break
            }
        }

        this.loading = true
        this.forceUpdate()

        let tmp = {...this.userDetails}
        tmp.mobile = this.mobile.replace("+","")
        tmp.parent = childFound

        let res = null
        if(this.userDetails.ID!==undefined && this.userDetails.ID!==""){
            res = await APICall("edit-respondent-user",tmp,"POST")
        }else{
            res = await APICall("add-respondent-user",tmp,"POST")
        }
        if(res.code===200){
            this.redirectToConfirmation = true
        }else{
            this.errorMessage = res.message
        }

        this.loading = false
        this.forceUpdate()
    }

    render(){

        if(this.redirectToConfirmation){
            return <Navigate to={"/verify-mobile/"+this.language} />
        }

        return(
            <div className="row noMargin">
                
                <div className="col-12 blueBg">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-right">
                                    <img src="/assets/img/swali_white.png" alt="Swali Logo" className="img-fluid" id="topLogo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="container"  id="registrationTopCol">
                            <div className="row" id="registrationFormRow">


                                <div className={"col-md-1 h-100 " +( this.language==="en" ? "" : "swRegistrationTitle")} id="registrationHolder">
                                        <h1 id="registrationFormTitle">{copy.registration[this.language]}</h1>
                                </div>
                                <div className="col">
                                    <form name="" method="post" onSubmit={this.submit}>
                                        {
                                            this.errorMessage === null ? null : 
                                            <p className={"text-center "+(this.success ? "text-success" : "text-danger")}>{this.errorMessage}</p>
                                        }
                                    <div className="row" id="registrationForm">

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.country[this.language]} <span className="text-danger">*</span></label>
                                                <input type="text" readOnly className='form-control' value={this.userDetails.country} />
                                                {/* <select required={true} name="country" className="form-control" value={this.userDetails.country} onChange={this.entryChange}>
                                                    <option value="">{copy.select_country[this.language]}</option>
                                                    {
                                                        getCountries().map((row,index)=>{
                                                            return <option value={row.name} key={index}>{row.name}</option>
                                                        })   
                                                    }
                                                </select> */}
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.region[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="region" value={this.userDetails.region} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_region[this.language]}</option>
                                                    {   
                                                        this.userDetails.country!=="" ? 
                                                            getCountryRegions(this.userDetails.country).map((row,index)=>{
                                                                return <option value={row} key={index}>{row}</option>
                                                            }) :
                                                            null
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-12 form-group">
                                                <label>{copy.phone_number[this.language]} <span className="text-danger">*</span></label>
                                                <div className="row">

                                                    <div className="col-sm-12 col-md-3">
                                                        <input required={true} type="text" readOnly={true} value={this.userDetails.area_code} className="form-control small-field noArrow" name="area_code" placeholder={copy.area_code[this.language]}/>
                                                    </div>

                                                    <div className="col-sm-12 col-md-9">
                                                        <input required={true} type="number" readOnly={true} value={this.userDetails.mobile} id="registrationMobileFormField" className="form-control m-auto noArrow" name="mobile_no" placeholder={copy.phone_number[this.language]}/>&nbsp;
                                                    </div>

                                                    
                                                </div>
                                            </div>


                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.gender[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="gender" value={this.userDetails.gender} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_gender[this.language]}</option>
                                                    {
                                                        getGenders().map((row,index)=>{
                                                            return <option value={row.en} key={index}>{row[this.language]}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.birth_year[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="birth_year" value={this.userDetails.birth_year} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_birth_year[this.language]}</option>
                                                    {
                                                     this.birthYears.map((row,index)=>{
                                                         return <option value={row} key={index}>{row}</option>
                                                     })   
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.relationship_status[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="relationship" value={this.userDetails.relationship} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_appropriate[this.language]}</option>
                                                    {
                                                        getRelationshipStatus().map((row,index)=>{
                                                            return <option value={row.en} key={index}>{row[this.language]}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-12"></div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.are_you_a_parent[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="parent" value={this.userDetails.parent} className="form-control" onChange={this.entryChange} >
                                                    <option>{copy.select_appropriate[this.language]}</option>
                                                    <option value={true}>{copy.yes[this.language]}</option>
                                                    <option value={false}>{copy.no[this.language]}</option>
                                                </select>
                                            </div>

                                            <div className="col-12"></div>
                                            {
                                                this.userDetails.parent === "true" ? <>
                                                    <div className="col-sm-12 col-md-4 form-group">
                                                        <label>{copy.child_n_birth_year[this.language].replace("{{NN}}",1)}</label>
                                                        <select className="form-control" value={this.userDetails.children[0]!==undefined ? this.userDetails.children[0].birth_year : ""} name="child_1" onChange={this.childBirthYearChange}>
                                                            <option>{copy.select_appropriate[this.language]}</option>
                                                            {
                                                                this.childBirthYears.map((row,index)=>{
                                                                    return <option value={row} key={index}>{row}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-sm-12 col-md-4 form-group">
                                                    <label>{copy.child_n_birth_year[this.language].replace("{{NN}}",2)}</label>
                                                    <select className="form-control" value={this.userDetails.children[1]!==undefined ? this.userDetails.children[1].birth_year : ""} name="child_2" onChange={this.childBirthYearChange}>
                                                            <option>{copy.select_appropriate[this.language]}</option>
                                                            {
                                                                this.childBirthYears.map((row,index)=>{
                                                                    return <option value={row} key={index}>{row}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="col-sm-12 col-md-4 form-group">
                                                    <label>{copy.child_n_birth_year[this.language].replace("{{NN}}",3)}</label>
                                                    <select className="form-control" value={this.userDetails.children[2]!==undefined ? this.userDetails.children[2].birth_year : ""} name="child_3" onChange={this.childBirthYearChange}>
                                                            <option>{copy.select_appropriate[this.language]}</option>
                                                            {
                                                                this.childBirthYears.map((row,index)=>{
                                                                    return <option value={row} key={index}>{row}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>

                                                </> : null
                                            }

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.education_level[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="education_level" value={this.userDetails.education_level} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_appropriate[this.language]}</option>
                                                    {
                                                        getEducationLevels().map((row,index)=>{
                                                            return <option value={row.en} key={index}>{row[this.language]}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.source_of_income[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="source_of_income" value={this.userDetails.source_of_income} className="form-control" onChange={this.entryChange}>
                                                    <option>{copy.select_appropriate[this.language]}</option>
                                                    {
                                                        getPrimarySourcesOfIncome().map((row,index)=>{
                                                            return <option key={index} value={row.en}>{row[this.language]}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.drinker[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="alcohol_consumption" value={this.userDetails.alcohol_consumption} className="form-control" onChange={this.entryChange}>
                                                    <option value="">{copy.select_appropriate[this.language]}</option>
                                                    {
                                                        getAlcoholConsumption().map((row,index)=>{
                                                            return <option key={index} value={row.en}>{row[this.language]}</option>

                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-sm-12 col-md-6 form-group">
                                                <label>{copy.where_do_you_keep_your_money[this.language]} <span className="text-danger">*</span></label>
                                                <select required={true} name="where_money_is_kept" value={this.userDetails.where_money_is_kept} className="form-control" onChange={this.entryChange}>
                                                    <option value="">{copy.select_appropriate[this.language]}</option>
                                                    {
                                                        getWhereMoneyIsKept().map((row,index)=>{
                                                            return <option key={index} value={row.en}>{row[this.language]}</option>

                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-12 text-center">
                                                <p className="text-center greenText" dangerouslySetInnerHTML={{__html:copy.registration_message[this.language]}}>
                                                </p>

                                                {
                                                    this.errorMessage === null ? null : 
                                                    <p className={"text-center "+(this.success ? "text-success" : "text-danger")}>{this.errorMessage}</p>
                                                }
                                                
                                                {
                                                    this.loading ? 
                                                    <span className="fa fa-spinner fa-spin fa-2x"></span>
                                                    :
                                                    <button className="btn btn-green">{copy.confirm[this.language]}</button>								
                                                }
                                            </div>

                                    </div>
                                    </form>
                                </div>


                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
//
export default CompleteRegistration