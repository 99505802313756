import React from 'react';
import { processLanguage, isValidNumber } from '../API';
import {copy} from "../Language"
import {Navigate} from "react-router-dom"
import { getCountries } from '../FormOptions';

class EnterMobileNo extends React.Component{

    constructor(props){
        super(props);
        this.language = processLanguage()
        this.mobileNumber = null
        this.selectedCountryIndex = null
        this.errorMessage = null
        this.success = false
        this.numberSet = false
    }

    submit = (e) =>{
        e.preventDefault()
        this.errorMessage = null

        if(this.selectedCountryIndex===null){
            this.errorMessage = copy.country_must_be_selected[this.language]
            this.success = false
            this.forceUpdate()
            return
        }

        if( this.mobileNumber===null ){
            this.errorMessage = copy.mobile_must_be_filled[this.language]
            this.success = false
            this.forceUpdate()
            return
        }

        this.mobileNumber = isValidNumber(this.mobileNumber,getCountries()[this.selectedCountryIndex].name)
        if( this.mobileNumber === false ){
            this.errorMessage = copy.invalid_mobile_no[this.language]
            this.success = false
            this.forceUpdate()
            return
        }    

        this.numberSet = true
        window.sessionStorage.setItem("mobile",this.mobileNumber)
        window.sessionStorage.setItem("country",getCountries()[this.selectedCountryIndex].name)
        this.forceUpdate()
    }


    render(){

        if(this.numberSet){
            return <Navigate to={"/introduction/"+this.language} />
        }

        return(
        <div className="row noMargin" id="numberForm">
            <div className="col-12 h-100 d-flex">
                <div className="row fillSpace">

                    <div className="col-12 formHolderTopLogoHolder">
                        <div className="row">
                            <div className="container">
                                <div className="row">

                                    <div className="col-12">
                                        
                                        <div className="text-center">
                                                <img src="/assets/img/swali_green.png" className="img-fluid" id="swaliLandingLogo" alt="Swali Logo"/>				
                                                <p>&nbsp;&nbsp;</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12 yellowBG formHolder">
                        <div className="row">

                            <div className="container">
                                <div className="row">

                                    <div className="col-12" >
                                            
                                        <div className="text-center">
                                            <p className="text-white"><strong>{copy.fill_phone_number[this.language]}</strong></p>
                                            <form onSubmit={this.submit}>
                                                {
                                                    this.errorMessage !== null ? <p className={this.success ? "text-success" : "text-danger"}>{this.errorMessage}</p> : null
                                                }
                                                <div id="countryDropDownField" className="dropdown d-inline">
                                                    <button className="form-control 
                                                        dropdown-toggle d-inline" type="button" 
                                                        id="dropdownMenuButton" 
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true" 
                                                        aria-expanded="false">
                                                    {
                                                        this.selectedCountryIndex === null ? 
                                                        copy.country[this.language] : 
                                                        <img 
                                                            id="selectedCountryFlag"
                                                            src={getCountries()[this.selectedCountryIndex].flag} 
                                                            alt={getCountries()[this.selectedCountryIndex].name}
                                                        />
                                                    }
                                                </button>       
                                            <ul id="countrySelector" className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {
                                                        getCountries().map((row,index)=>{
                                                            return <li 
                                                                    className={this.selectedCountryIndex === index ? "active" : ""} 
                                                                    key={index} 
                                                                    onClick={()=>{
                                                                        this.selectedCountryIndex = index
                                                                        this.forceUpdate()
                                                                    }}
                                                                >
                                                                <img 
                                                                    src={row.flag}
                                                                    className='img-fluid countryFlag'
                                                                />
                                                                &nbsp;
                                                                <span>{row.name}</span>
                                                            </li>
                                                        })
                                                    }

                                            </ul> 
                                            </div>                                          
                                                <input id="mobileNumberField" onChange={(e)=>this.mobileNumber = e.target.value} type="tel" className="form-control text-center noArrow m-auto d-inline"  placeholder={copy.phone_number[this.language]} />
                                                <button href="" className="btn btn-green">{copy.next[this.language]}</button>
                                            </form>
                                        </div>
                                        
                                    
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>

                </div>

            </div>
        </div>
        )
    }
}
//
export default EnterMobileNo