import React from "react"
import { BrowserRouter,Routes,Route } from "react-router-dom";
import AnswerQuestions from "./Components/AnswerQuestions";
import CompleteRegistration from "./Components/CompleteRegistration";
import EnterMobileNo from "./Components/EnterMobileNumber";
import LandingPage from "./Components/landing-page";
import QuestionsGroup from "./Components/QuestionsGroup";
import RegistrationCompleted from "./Components/RegistrationCompleted";
import StartSurvey from "./Components/StartSurvey";
import SurveyEnded from "./Components/SurveyEnded";
import IntroductionScreens from "./Components/IntroductionScreens";
import VerifyMobile from "./Components/VerifyMobile";

class App extends React.Component{
  render(){
    return (
      <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/start-registration/:lang" element={<EnterMobileNo />} />
            <Route path="/introduction/:lang" element={<IntroductionScreens />} />
            <Route path="/complete-registration/:lang" element={<CompleteRegistration />} />
            <Route path="/verify-mobile/:lang" element={<VerifyMobile />} />
            <Route path="/registration-completed/:lang" element={<RegistrationCompleted />}  />
            <Route path="/start-survey/:broadcastID" element={<StartSurvey /> } />
            <Route path="/survey-section/:lang" element={<QuestionsGroup />}  />
            <Route path="/answer-questions/:lang" element={<AnswerQuestions />}  />
            <Route path="/survey-ended" element={<SurveyEnded />} />
          </Routes>
      </BrowserRouter>
    )
  }
}

export default App;
