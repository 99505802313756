import React, { useState } from "react";
import {copy} from "../Language"
import { Navigate, useParams } from "react-router-dom";
import NextImage from "../assets/next.png"

function IntroductionScreens(){
    const [screenNo,updateScreenNo] = useState(1)
    const {lang} = useParams()

    const next = () =>{
        updateScreenNo(screenNo+1)
    }

    const previous = () =>{
        updateScreenNo(screenNo-1)
    }

    if(screenNo===3){
        return <Navigate to={"/complete-registration/"+lang} />
    }

    return (
        <div className="row noMargin" id="">
            <div className="col-12 h-100 d-flex">
                <div className="row fillSpace">

                    <div className="col-12">
                        <div className="row">
                            <div className="container">
                                {
                                    screenNo ===  1 ? 

                                        <div className="row">

                                            <div className="col-12">
                                                
                                                <div className="text-center">
                                                        <img src="/assets/img/swali_green.png" className="img-fluid" id="swaliHowitWorksLogo" alt="Swali Logo"/>				
                                                        <h4 className="greenText">{copy.how_it_works_title[lang]}</h4>
                                                </div>

                                                <div className="howItWorksBody" dangerouslySetInnerHTML={{__html:copy.how_it_works_body[lang]}}>
                                                </div>
                                                
                                                <div className="text-center" onClick={next}>
                                                    <img src={NextImage} alt="next" className="img-fluid cursor" />
                                                    <br/>
                                                    <span className="btn btn-green">
                                                        {copy.continue[lang]}
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </div>

                                    : 


                                    <div className="row">

                                        <div className="col-12">
                                            
                                            <div className="text-center">
                                                    <img src="/assets/img/swali_green.png" className="img-fluid" id="swaliHowitWorksLogo" alt="Swali Logo"/>				
                                                    <h4 className="greenText">{copy.important_stuff_to_know_title[lang]}</h4>
                                            </div>

                                            <div className="howItWorksBody" dangerouslySetInnerHTML={{__html:copy.important_stuff_to_know_body[lang]}}>
                                            </div>
                                            
                                            <div className="horizontalContainerWithSpaceBetween">
                                                <div className="nextLink cursor" onClick={previous}>
                                                    <img src={NextImage} alt="next" className="img-fluid cursor backFacing" />                                                
                                                    <span className="btn btn-green">
                                                        {copy.continue[lang]}
                                                    </span>
                                                </div>
                                                <div className="nextLink cursor" onClick={next}>
                                                    <span className="btn btn-green">
                                                        {copy.create_account[lang]}
                                                    </span>
                                                    <img src={NextImage} alt="next" className="img-fluid cursor" />                                                
    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                }
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </div>

    )
}

export default IntroductionScreens