import React from 'react';
import {Link} from "react-router-dom"
import {copy} from "../Language"
import KRMLogo from "../assets/krm-logo.png"

class LandingPage extends React.Component{

    constructor(props){
        super(props);
        this.language = null
    }

    setLanguage = (language) =>{
        this.language = language
        this.forceUpdate()
    }

    

    render(){

        return(
            <div className="row picturedBG noMargin coverBG"> 
                <div className="container">
                    <div className="row h-100">

                        <div className="col-12" id="landingPageContainer">
                            
                            <div className="text-center">
                                    <img src="./assets/img/swali_white.png" className="img-fluid" id="swaliLandingLogo" alt="Swali Logo"/>				
                            </div>
                            

                            {
                                this.language === null ? 
                                
                                <div className="text-center">
                                    <h1 className="text-white">{copy.select_language.en+"/"+copy.select_language.sw}</h1>
                                    <span onClick={()=>this.setLanguage("en")} className="btn btn-white" title="Use english">English</span>
                                    &nbsp;&nbsp;
                                    <span onClick={()=>this.setLanguage("sw")} className="btn btn-white" title="Use Swahili">Swahili</span>
                                </div>

                                
                                : <>

                                    <div className="text-center">
                                        <h1 className="text-white">{copy.answer_question[this.language]}</h1>
                                        <Link to={"/start-registration/"+this.language} className="btn btn-white">{copy.get_started[this.language]}</Link>
                                    </div>
                                
                                </> 
                            }
                            
                            <div className="logoBottom">
                                    <img src={KRMLogo} className="img-fluid bottomLogo float-right" alt="Khangarue" />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
//
export default LandingPage