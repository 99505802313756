import axios from 'axios';
import {HMACSHA256} from "./jwt";

const eventTimeouts = 15000
let intervalSetted = null

const JWTsecret = "dc2c5abc8047146f85ded3096c88274536976dfcd85547d033b23f384c54f1174ed56ab0ef27f9259c5e2de6de468d812654231104769da6790061507b63424e";

//constants for jwt headers
const JWT_HEADER = {
  alg: "HS256",
  typ: "JWT",
};


const BaseURL = "https://www.swalitime.com:8585/"

let respondentUser = null
let broadcastDetails = null

export function setRespondentUser(respondentUserData){
  respondentUser = {...respondentUserData}
  return respondentUser
}

export function getRespondentUser(){
  return respondentUser
}

export function setBroadcastDetails(details){
  broadcastDetails = {...details}
  return broadcastDetails
}

export function getBroadcastDetails(){
  return broadcastDetails
}

//This is for the Mokiwa platforms, omnce we launch a new interface with dynamic interfaces will be deployed
//for the overall public
export const CommonTitle = " Khangarue Website"
export const CurrentYear = new Date().getFullYear()
export const APICallCount = 30
export const ResourcesBaseURL = "https://swali-portal.s3-eu-west-1.amazonaws.com/images/"
export const MaxLength = 1000
export const MaxFileSize = 6200000
export const MaxFileSizeLabel = "6MB"
export const MaxMessageLength = 4096
export const MaxMessageLengthWithAttachment = 1024
export const AssetsBaseURL = "https://swali-portal.s3.eu-west-1.amazonaws.com/images/"

export function getLogo(){
    return "https://noa-ubongo.s3-eu-west-1.amazonaws.com/web-assets/img/latest-logo.png"
}

export function getName(){
    return "Swali Portal"
}

//url to redirect to when login is succesful and/or when refreshing pages
export const prevUrl = (window.location.pathname === "" || window.location.pathname === "/") ? "/trips" : window.location.pathname.replace("/"+getName().toLowerCase(),"")+window.location.search

export async function APICall(url,payload,method="POST",timeoutAllowed = eventTimeouts,port=null){
  let response = {body:null,error:null}
   method = method.toUpperCase()
   if(payload===null){
     payload = {rand:new Date()}
   }

  url = BaseURL+url

  try{
       response.body = await axios({
            method: method,
            url: url,
            data: JSON.stringify(payload),
            headers: {  
                'Accept': 'application/json',
                'jwt-token':HMACSHA256(JWT_HEADER,payload,JWTsecret)
            },
            timeout: timeoutAllowed,
            withCredentials: true
       })    
       response = response.body.data
   }catch(error){
      response.error = error.toString()
   }

  return response
}

export function Trim(entry){
  entry = entry.split(" ")
  entry = entry.join("")
  return entry
}

export function logout(){
  clearInterval(intervalSetted)
  setCookie("client",null)
  setCookie("loggedIn","")
  setCookie("loggedInName","")
  setCookie("interface","")
  setCookie("access-token","")
}

export function getCookie(cname){
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname,cvalue){
  document.cookie = cname+"="+cvalue+";expires=Thu, 31 Dec 2030 11:59:00 UTC;path=/;samesite=strict"
}

export function numberWithCommas(number){
  number = parseFloat(number).toFixed(2)
  const tmp = number.split(".00")
  if(tmp.length===2){
    number = tmp[0]
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function iosCopyToClipboard(el) {
    var oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contentEditable = true;
    el.readOnly = false;
    range.selectNodeContents(el);

    var s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;
}

export function copyToClipBoard(text){
  let tmpElement = document.createElement("textarea")
  tmpElement.value = text;
  tmpElement.setAttribute('readonly', '');
    tmpElement.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(tmpElement);
    // Select text inside element
    tmpElement.select();
    tmpElement.select()

  let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if(iOS===true){
    iosCopyToClipboard(tmpElement)
  }
  document.execCommand("copy")
  // Remove temporary element
    document.body.removeChild(tmpElement);
  alert("Umefanikiwa kukopi link")
}

function IsValidTZNo(number){
  number = Trim(number)
  if(number===""){
    return false
  }
  if( number.length < 9 || number.length > 13){
    return false
  }

  if(number[0]==="0" && number.length===10){
    number = "+255"+number.substring(1)
    return number
  }else if(number[0]!==0 && number.length===9){
    number = "+255"+number
    return number
  }else if(number[0]==="+" && number.length===13){
    return number
  }
  else if(number[0]==="2" && number.length===12 && number[1]==="5" && number[2]==="5"){
    number = "+"+number
    return number
  }

  return false
}

export function CheckIfNumbersAreValid(numbers){
  numbers = numbers.split(",")
  for(let x = 0 ; x < numbers.length ; x++){
    if(IsValidTZNo(numbers[x])===false){
      return false
    }
  }
  return numbers
}

export async function StoreData(key,value){
  if ( typeof(Storage) !== "undefined") {
    localStorage.setItem(key, value);
  } else {
      alert("Sorry but your browser does not support the local storage feature")
  }  
}

export function GetData(key){
  return localStorage.getItem(key)
}

export function getDateRanges(startDate = new Date(), days = 7){
  let returnDates = {startDate:startDate,endDate:null}
//  this function only brings past dates from the one set as starting
  if( days > 0 ){
    days *= -1
  }
  const secondsInaDay = 24 * 60 * 60
  const secondsToAffect = secondsInaDay * days

  let date = new Date(startDate)
  let day = date.getDate() 
  let month = date.getMonth()  + 1
  let year = date.getFullYear()

  if(month<10){
    month = "0"+month
  }
  if(day<10){
    day = "0"+day
  }

  returnDates.startDate = year+"-"+month+"-"+day

  let seconds1 = date.getTime() / 1000 //dividing by 1000 as javascript deals in milliseconds and not seconds
  let seconds2 = seconds1 + secondsToAffect
  
  seconds2 *= 1000 //multiplying by 1000 to have these values in milliseconds which is what javascript deals with
  date = new Date(seconds2)
  
  day = date.getUTCDate() 
  month = date.getUTCMonth() + 1
  year = date.getUTCFullYear()


  if(month<10){
    month = "0"+month
  }
  if(day<10){
    day = "0"+day
  }
  returnDates.endDate = year+"-"+month+"-"+day

  return returnDates
}

export function getDate(dateString){
  let x = new Date(dateString)
  x = x.getUTCDate()+"/"+(x.getUTCMonth()+1)+"/"+x.getUTCFullYear()
  return x
}

export function fileUpload(event,callBack,target){
    if(window.File && window.FileReader){
      let files = event.target.files
      if(files.length>0){

          event.persist()
          
          if(files[0].size>MaxFileSize){
            alert("File size can not exceed "+MaxFileSizeLabel)
              event.target.value=""
            return
          }else{
            let reader = new FileReader();

            // Closure to capture the file information.
            reader.onload = (function(theFile) {
              return function(e) {
                /*
                // Render thumbnail.
                var span = document.createElement('span');
                span.innerHTML = ['<img class="thumb" src="', e.target.result,
                                  '" title="', escape(theFile.name), '"/>'].join('');
                document.getElementById('list').insertBefore(span, null);
                */
                callBack(e.currentTarget.result,target,event)
              };
            })(files[0]);

            reader.readAsDataURL(files[0])     
          }

      }
    }else{
      alert("Browser not supported");
    }
  }

/*
  This function handles attachment link issues for chat API as user messages with attachment will have some 
  texts in their messages encoded and adding some unwanted characters, we therefore replace those characters
  This only affects incomming messages
*/  
export function processAttachmentLink(attachmentLink){
    attachmentLink = attachmentLink.split("!F(MISSING)")
    attachmentLink = attachmentLink.join("2F")
    return attachmentLink
}

export function processLanguage(){
  let language = window.top.location.href.split("/")
  language = language[language.length-1].trim(" ")
  language = language.split("?")
  language = language[0].toLowerCase()

  if(language!=="en" && language!=="sw"){
      language = "en"
  }

  return language
}

export function isValidNumber(number,country){
  switch(country.toLowerCase()){
    case "tanzania":
      return isValidTZNo(number)
    default:
      return false
  }
}

export function isValidTZNo(number) {

  number = number.trim("");
  if (number === "") {
      return false;
  }

  if (number.length < 9 || number.length > 13) {
      return false;
  }

  if (number[0] === "0" && number.length === 10) {
      number = "+255" + number.substring(1);
      return number;
  } else if (number[0] !== "0" && number.length === 9) {
      number = "+255" + number;
      return number;
  } else if (number[0] === "+" && number.length === 13) {
      return number;
  } else if (
      number[0] === "2" &&
      number.length === 12 &&
      number[1] === "5" &&
      number[2] === "5"
  ) {
      number = "+" + number;
      return number;
  }

  return false;
}

/* Question groups handling center */
let questionGroups = []
let currentGroupIndex = null

export function getCurrentGroupIndex(){
  return currentGroupIndex
}

export function getGroupsCount(){
  return questionGroups.length
}

export function getCurrentGroup(){
  return questionGroups[currentGroupIndex]
}

export function setQuestionGroups(groups){
  if(groups.length>0){
    currentGroupIndex = 0
    questionGroups = groups.slice()
    return getCurrentGroup()
  }
  return null
}

export function navigateGroups(direction="next"){
  switch(direction){
    case "next":
      if( currentGroupIndex < questionGroups.length-1 ){
        currentGroupIndex++
      }
      break;
    
    case "previous":
      if( currentGroupIndex > 0 ){
        currentGroupIndex--
      }
      break;
    
      default:
        break
  }

  return getCurrentGroup()
}

let lastQuestionResponse = null
export function setLastQuestionResponse(questionResponse){
  lastQuestionResponse = JSON.parse(JSON.stringify(questionResponse))

  return lastQuestionResponse
}

export function getLastQuestionResponse(){
  return lastQuestionResponse
}

export function processMedia(questionMedia,language,mediaCompleted){
  if(questionMedia.video[language]!==""){

      let path = questionMedia.video[language].indexOf(";") >= 0 ? questionMedia.video[language] : ResourcesBaseURL+questionMedia.video[language];
      return(
        <video id="mediaPlayer" className="img-fluid" controls onEnded={()=>{
                  if(mediaCompleted!==null){
                      mediaCompleted()
                  }
                }
              }>
          <source src={path} type="audio/mp3" />
        </video>
      )

  }else if(questionMedia.audio[language]!==""){

    let path = questionMedia.audio[language].indexOf(";") >= 0 ? questionMedia.audio[language] : ResourcesBaseURL+questionMedia.audio[language];
    return (
      <audio id="mediaPlayer" controls className="max250"  onEnded={()=>{
                  if(mediaCompleted!==null){
                      mediaCompleted()
                  }
                }
              }>
        <source src={path} type="audio/mp3" />
      </audio>
    )

  }else if(questionMedia.photo[language]!==""){
    
    let path = questionMedia.photo[language].indexOf(";") >= 0 ? questionMedia.photo[language] : ResourcesBaseURL+questionMedia.photo[language];
    return <img src={path} className="img-fluid max250" alt="media" />
  
  }else{
  
    return null
  
  }
}

let language = null
export function setLanguage(lan){
  language = lan.toLowerCase()
  return getLanguage()
}

export function getLanguage(){
  return language
}