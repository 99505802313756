import React from 'react';
import {Navigate} from "react-router-dom"
import { APICall,setRespondentUser,setBroadcastDetails, setQuestionGroups } from '../API';

class StartSurvey extends React.Component{

    constructor(props){
        super(props);
        this.language = null
        this.broadcastID = window.top.location.href.split("/")
        this.broadcastID = this.broadcastID[this.broadcastID.length-1]

        this.state = {
            loading:true,
            error:null,
            surveyData:null,
            revealCategory:false
        }
    }

    componentDidMount(){
        this.getAssociatedBroadcast()
    }

    getAssociatedBroadcast = async () =>{
        const res = await APICall("get-broadcast-record?broadcastID="+this.broadcastID,null,"GET")
        if(res.code===200){
            if(res.payload.status!=="SENT"){
                this.setState({loading:false,error:"You can not take this survey again, Please wait for a new one to be added then you will receive a notification"})
            }else{
                setRespondentUser({
                    ID:"test",
                    country:"Tanzania",
                    mobile:"255762016002",
                    status:"ACTIVATED",
                    language:"English"
                })
                setBroadcastDetails(res.payload)
                this.getAssociatedUser(res.payload.user_id,res.payload.survey_id,res.payload.for_preview)    
            }
        }else{
            this.setState({loading:false,error:res.message})
        }
    }

    getAssociatedUser = async (userID,surveyID,forPreview) =>{
        if(forPreview===true){
            this.language = "English"
            this.getAssociatedSurvey(surveyID)
        }else{
            const res = await APICall("get-respondent-user?userID="+userID,null,"GET")
            if(res.code===200){
                setRespondentUser(res.payload)
                this.language = res.payload.language.toLowerCase()
                this.getAssociatedSurvey(surveyID)
            }else{
                this.setState({loading:false,error:res.message})
            }
    
        }
    }

    togglePlayer = () =>{
        let x = document.getElementById("mediaPlayer")
        if(x.paused){
            x.play()
        }else{
            x.pause()
        }
    }

    getAssociatedSurvey = async (surveyID) =>{
        const res = await APICall("get-survey?surveyID="+surveyID,null,"GET")
        if(res.code===200){ 

            const millisecondsInaDay = 86400000
            const endTstamp = new Date(res.payload.end_date).getTime()+millisecondsInaDay
            const nowTstamp = new Date().getTime()
            if(nowTstamp>endTstamp){
                this.setState({loading:false,error:"You can not proceed with this survey as it's time has already passed"})
                return
            }

            this.getSurveyQuestionsGroup(surveyID)
        }else{
            this.setState({loading:false,error:res.message})
        }
    }

    getSurveyQuestionsGroup = async (surveyID) =>{
        const res = await APICall("get-question-groups?surveyID="+surveyID+"&start=0&count=1000",null,"GET")
        if(res.code===200){
            const group = setQuestionGroups(res.payload)
            if(group !== null){
                this.setState({loading:false,revealCategory:true})
            }else{
                this.setState({loading:false,error:"It appears this survey does not have questions associated with it!"})
            }
        }else{
            this.setState({loading:false,error:res.message})
        }
    }

    render(){
        if(this.state.revealCategory){
            return <Navigate to={"/survey-section/"+this.language} />
        }

        if(this.state.loading){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <span className="fa fa-4x fa-spin fa-spinner"></span>
                        <p>Preparing... Please wait!</p>
                    </div>
                </div>
            )
        }

        if(this.state.error!==null){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <h4 className="text-danger">{this.state.error}</h4>
                    </div>
                </div>
            )
        }

        return null
    }
}
//
export default StartSurvey