import React from 'react';
import {copy} from "../Language"
import { APICall,getRespondentUser, getBroadcastDetails, setLastQuestionResponse, ResourcesBaseURL, processMedia, getLanguage } from '../API';
import {languageDecoder} from "../FormOptions"
import {Navigate} from "react-router-dom"

class AnswerQuestions extends React.Component{

    constructor(props){
        super(props);
        this.language = null
        this.surveyID = null
        this.groupID = window.top.location.href.split("/")
        this.groupID = this.groupID[this.groupID.length-1]

        this.currentQuestion = 0
        this.totalQuestions = 0

        this.state = {
            loading:true,
            error:null,
            submiting:false,
            submiting_error:null,
            questions:[]
        }
        this.respondentUser = this.broadcastDetails = null
        this.answers = {}
        this.surveyEnded = false
    }

    componentDidMount(){
        this.respondentUser = getRespondentUser()
        this.broadcastDetails = getBroadcastDetails()
        if(this.respondentUser === null || this.broadcastDetails===null){
            this.setState({error:"Could not load page!",loading:false})
            return
        }
        this.language = this.respondentUser.language.toLowerCase() === "english" || this.respondentUser.language.toLowerCase() === "en" ? "en" : "sw"

        this.getGroupQuestions()
    }

    getGroupQuestions = async () =>{
        const res = await APICall("get-questions?start=0&count=1000&groupID="+this.groupID,null,"GET")
        if(res.code===200){
            this.totalQuestions = res.payload === null ? 0 : res.payload.length
            this.surveyID = this.totalQuestions === 0 ? null : res.payload[0].survey_id
            this.setState({loading:false,questions:res.payload})
        }else{
            this.setState({loading:false,error:res.message})
        }
    }

    selectedAnswer = (question,answer) =>{
        this.answers[question] = answer
        this.forceUpdate()
    }
    
    nextPreviousQuestion = async (direction) =>{
        let lastQuestion = false
        let res = true
        switch(direction.toLowerCase()){
            case "next":
                res = null
                if(this.currentQuestion===this.totalQuestions-1){
                    lastQuestion = true
                    res = await this.submitResponse(false,this.answers[this.currentQuestion])
                }else{
                    res = await this.submitResponse(false,this.answers[this.currentQuestion])
                    this.currentQuestion++
                }

                this.surveyEnded = lastQuestion

                break
            
            case "previous":
                this.currentQuestion--
                break;

            default:
                break
        }

        if(res===false){
            this.setState({submiting:false,submiting_error:copy.could_not_submit[this.language]})
        }else{
            this.setState({submiting:false})
        }

    }

    submitResponse = async (lastQuestion,answer) =>{
        const questionResponse = {
            survey_id : this.surveyID,
            user:this.respondentUser,
            question_id:this.state.questions[this.currentQuestion].ID,
            answer:answer,
            broadcast_id:this.broadcastDetails.ID,
            last_question:lastQuestion
        }

        setLastQuestionResponse(questionResponse)

        this.setState({submiting:true,submiting_error:null})
        const res = await APICall("add-response",questionResponse,"POST")
        if(res.code===200){
            return true
        }else{
            return false
        }
    }

    processQuestionAnswers = (questionAnswers,questionType) =>{
        let answers = null
        switch(questionType.toLowerCase()){
            case "photo":
                answers = questionAnswers.map((row,index)=>{
                    return (
                            <div key={index} className="col-sm-10 col-md-3 text-center">
                                <div className={"answerOption text-center "+(this.answers[this.currentQuestion]===row.answer_body.english ? "active": "")} onClick={()=>this.selectedAnswer(this.currentQuestion,row.answer_body.english)}>
                                    <img src={ResourcesBaseURL+row.answer_body[languageDecoder(this.language)]} className="img-fluid" alt="Swalitime" />
                                </div>
                            </div>

                    )
                })
                answers = <div className="row noMargin noPadding">{answers}</div>
                break;
            
            case "text":
                    answers = questionAnswers.map((row,index)=>{
                        return (
                            <div className="row noMargin" key={index}>
                                <div className={"col-12 answerOption "+(this.answers[this.currentQuestion]===row.answer_body.english ? "active": "")} onClick={()=>this.selectedAnswer(this.currentQuestion,row.answer_body.english)}>
                                    {row.answer_body[languageDecoder(this.language)]}
                                </div>
                            </div>

                        )
                    })
    
                break
                
            default:
                break
        }

        return answers
    }

    render(){
        if(this.surveyEnded){
            return <Navigate to={"/survey-section/"+this.language+"?next=true"} />
        }

        if(this.state.loading){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <span className="fa fa-4x fa-spin fa-spinner"></span>
                    </div>
                </div>
            )
        }

        if(this.state.error!==null){
            return (
                <div className="row noMargin">                
                    <div className="col-12 text-center">
                        <p><br/><br/></p>
                        <h4 className="text-danger">{this.state.error}</h4>
                    </div>
                </div>
            )
        }


        return(

            <div className="row noMargin">
	
                <div className="col-12 blueBg">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-right">
                                    <img src="/assets/img/swali_white.png" alt="swali logo" className="img-fluid" id="topLogo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                    {
                                        this.state.questions === null ? <h1>{copy.no_questions[this.language]}</h1> :
                                        this.state.questions.map((row,index)=>{
                                            if(index===this.currentQuestion){

                                                return (<div key={index} className="col-sm-10 col-md-9 m-auto question">
                                                    <p className="yellowText questionLabel"><strong>{copy.question[this.language]} {this.currentQuestion+1}</strong></p>
                                                    <p className="questionBody greenText">{row.question_body[languageDecoder(this.language)]}</p>
                                                    <div>{processMedia(row.question_media,languageDecoder(this.language))}</div>
                                                        {
                                                            this.processQuestionAnswers(row.answers,row.question_type)
                                                        }                       
            
                                                </div>)
                                            }

                                            return null
    
                                        })
                                    }                                
                            </div>

                            <div className="row">
                                    <div className="col-12 text-center">
                                        {
                                            this.state.submiting ? 
                                            <span className="fa fa-2x- fa-spin fa-spinner"></span> :
                                            null
                                        }
                                        <p className="text-danger">{this.state.submiting_error}</p>
                                    </div>
                                    <div className="col-6 text-left">
                                        {
                                        this.currentQuestion === 0 ? null : 
                                            <span onClick={()=>this.nextPreviousQuestion("previous")} className="greenText nextLink" ><img src="/assets/img/next.png" className="img-fluid backFacing" alt="next" /> {copy.previous_question[this.language]} </span>
                                        }
                                    </div>
                                    <div className="col-6 text-right">
                                    <span onClick={()=>this.nextPreviousQuestion("next")} className={"greenText nextLink "+(this.answers[this.currentQuestion]===undefined ? "noClick" : "")} >{copy.next_question[this.language]} <img src="/assets/img/next.png" className="img-fluid" alt="next" /></span>
                                    </div>
                                    <div className="col-12"><p><br/></p></div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        )

    }
}
//
export default AnswerQuestions